var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"width":"300","hide-details":"","single-line":"","placeholder":"Busca (nome do produto)","append-icon":"search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('export-to-excel',{attrs:{"disabled":_vm.$apollo.loading,"btn-class":"mx-2","filename":"active_products","fields":_vm.fields,"fetch-fn":_vm.loadExportItems}})],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-n6",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('headers-data-table',{attrs:{"headers":_vm.headers,"default-headers":_vm.getDefaultHeadersOptions(),"headers-settings-name":"headers-list-products-retailers"},on:{"update:headers":function($event){_vm.headers=$event}}})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.$apollo.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"server-items-length":_vm.maxRecords,"options":_vm.options,"headers":_vm.computedHeaders,"items":_vm.activeProductsData,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getProductFromType(item.product_type).name)+" ")])],1)]}},{key:"item.unit_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.mountUnitValue(item))+" ")])],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.active ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.active ? 'Ativo' : 'Inativo')+" ")],1)],1)]}},{key:"item.temporary_churn",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":item.temporary_churn ? 'error' : 'success'}},[_vm._v(_vm._s(item.temporary_churn ? 'mdi-alert' : 'mdi-check-circle'))]),_vm._v(_vm._s(item.temporary_churn ? 'Sim' : 'Não')+" ")],1)],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.sale_date ? _vm.formatDate(item.sale_date) : _vm.formatDate(item.created_at))+" ")])],1)]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")])],1)]}},{key:"item.billing_date",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.billing_date ? _vm.formatDate(item.billing_date) : '-')+" ")])],1)]}},{key:"item.total_mrr",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.total_mrr))+" ")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }