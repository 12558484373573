<template>
  <v-card class="mt-3" flat>
    <v-card-title>
      <v-text-field
        v-model="search"
        class="pt-0 mt-0"
        width="300"
        hide-details
        single-line=""
        placeholder="Busca (nome do produto)"
        append-icon="search"
        outlined
      />
      <v-spacer />
      <export-to-excel :disabled="$apollo.loading" btn-class="mx-2" filename="active_products" :fields="fields" :fetch-fn="loadExportItems" />
    </v-card-title>
    <v-card-text>
      <v-row justify="end" class="mt-n6">
        <v-col cols="auto">
          <headers-data-table :headers.sync="headers" :default-headers="getDefaultHeadersOptions()" headers-settings-name="headers-list-products-retailers" />
        </v-col>
      </v-row>
      <v-data-table
        :loading="$apollo.loading"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
        :server-items-length="maxRecords"
        :options.sync="options"
        :headers="computedHeaders"
        :items="activeProductsData"
        :search="search"
      >
        <template v-slot:[`item.product_type`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ getProductFromType(item.product_type).name }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.unit_value`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ mountUnitValue(item) }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              <v-icon class="mr-2" x-small :color="item.active ? 'success' : 'error'">mdi-brightness-1</v-icon>{{ item.active ? 'Ativo' : 'Inativo' }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.temporary_churn`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              <v-icon class="mr-2" small :color="item.temporary_churn ? 'error' : 'success'">{{
                item.temporary_churn ? 'mdi-alert' : 'mdi-check-circle'
              }}</v-icon
              >{{ item.temporary_churn ? 'Sim' : 'Não' }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ item.sale_date ? formatDate(item.sale_date) : formatDate(item.created_at) }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ formatDate(item.updated_at) }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.billing_date`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ item.billing_date ? formatDate(item.billing_date) : '-' }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.total_mrr`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ parseCurrencyDefault(item.total_mrr) }}
            </v-layout>
          </v-flex>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { QUERY_GET_AVAILABLE_PRODUCTS } from '@/modules/retailers/graphql'
import { parseCurrency, headers, productsInformation } from '@/mixin'
import { tableHeaders } from '@/constants'
import { cloneDeep } from 'lodash'
import { DateTime } from 'luxon'

export default {
  components: {
    ExportToExcel: () => import('@/components/imports/ExportToExcel.vue'),
    HeadersDataTable: () => import('@/components/atomic-components/molecules/HeadersDataTable')
  },
  mixins: [parseCurrency, headers, productsInformation],
  props: {
    clientId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    search: '',
    options: {},
    maxRecords: 0,
    activeProductsData: [],
    exportData: [],
    dialogSetDate: false,
    headers: []
  }),
  computed: {
    fields() {
      return {
        ID_Produto: '_id',
        Produto: 'product_type',
        Ativo: 'active',
        Total_MRR: 'total_mrr',
        Slots: 'slots',
        Criado_em: 'created_at',
        Atualizado_em: 'updated_at'
      }
    }
  },
  apollo: {
    availableProducts: {
      query: QUERY_GET_AVAILABLE_PRODUCTS,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { client_id: this.$route.params.id },
          { filters: {} },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ availableProducts: { count, data } }) {
        this.maxRecords = count
        this.activeProductsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  mounted() {
    if (this.headers.length < 1) this.headers = cloneDeep(tableHeaders.avaliableProducts)
  },
  methods: {
    getDefaultHeadersOptions() {
      return cloneDeep(tableHeaders.avaliableProducts)
    },
    openDialogSetDate() {
      this.dialogSetDate = true
    },
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('dd/MM/yyyy')
    },
    mountUnitValue(sale) {
      const unitVal = sale.unit_value ? sale.unit_value : sale.total_mrr / sale.slots
      return sale.product_type === 'sms' ? this.parseCurrencySms(unitVal) : this.parseCurrencyDefault(unitVal)
    },
    async loadExportItems() {
      try {
        const variables = Object.assign(
          {},
          { client_id: this.$route.params.id },
          { filters: {} },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )

        const result = await this.$apollo.query({
          query: QUERY_GET_AVAILABLE_PRODUCTS,
          fetchPolicy: 'no-cache',
          variables,
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['crm']
          }
        })

        let data = result.data.availableProducts.data
        data = await Promise.all(
          data.map(item => {
            item.product_type = this.getProductFromType(item.product_type).name
            item.active = item.active ? 'Sim' : 'Não'
            item.total_mrr = this.parseCurrencyDefault(item.total_mrr)

            return item
          })
        )

        return data
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Erro ao exportar dados.', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
